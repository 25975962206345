import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import moment from "moment"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../graphql/fragments"

import { Breadcrumbs } from "../components/breadcrumbs/breadcrumbs"
import { Container, Section } from "../components/grid/grid"
import { PostHeader, PostContent } from "../components/post/post"
import { replaceUnicode } from "../components/utils/utils"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const TNCPage = location => {
  const data = useStaticQuery(graphql`
    query {
      wordPress {
        pageBy(uri: "term-and-conditions") {
          slug
          title
          blocks {
            name
            ...CoreParagraphBlock
            ...CoreHeadingBlock
            ...CoreImageBlock
            ...CoreButtonBlock
            ...CoreShortcodeBlock
            ...CoreCoverBlock
            ...CoreGroupBlock
          }
          date
          translation(language: ID) {
            title
            blocks {
              name
              ...CoreParagraphBlock
              ...CoreHeadingBlock
              ...CoreImageBlock
              ...CoreButtonBlock
              ...CoreShortcodeBlock
              ...CoreCoverBlock
              ...CoreGroupBlock
            }
          }
          seo {
            title
            metaDesc
            twitterTitle
            twitterDescription
            opengraphTitle
            opengraphDescription
          }
        }
      }
    }
  `)

  const wordpress = data?.wordPress?.pageBy?.blocks
  const seo = data.wordPress.pageBy.seo

  return (
    <Layout location={location} currentLocation={location.uri} lang="EN">
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle || seo?.title}
        twitterTitle={seo?.twitterTitle || seo?.title}
        twitterDescription={seo?.twitterDescription || seo?.opengraphDescription}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        url={location?.href}
      />

      <section className="sc-breadcrumb d-none d-lg-block">
        <div className="container container-md">
          <Breadcrumbs path={location.uri} />
        </div>
      </section>

      <Section className="pt-0">
        <Container className="mw-lg">
          <PostHeader
            title={data?.wordPress?.pageBy?.title}
            category={`Last modified on
                ${moment(data.wordPress.pageBy.date).format("MMM Do, YYYY")}`}
          />
          <PostContent>
            {wordpress?.map((item, i) => {
              if (i > 0) {
                if (item.name === "core/heading") {
                  if (item.attributes.level === 2) {
                    return <h3>{replaceUnicode(item.attributes.content)}</h3>
                  } else if (item.attributes.level === 3) {
                    return <h4>{replaceUnicode(item.attributes.content)}</h4>
                  }
                } else if (item.name === "core/paragraph") {
                  const paragraphs = item.attributes.content.split("&nbsp;")
                  return <p>{replaceUnicode(paragraphs.join(" "))}</p>
                }
              }
              return <div key={`empty-${i}`}></div>
            })}
          </PostContent>
        </Container>
      </Section>
    </Layout>
  )
}

export default TNCPage
